import React from 'react';
import { Notification, Content, ErrorIcon, SuccessIcon, MaintenanceIcon } from './styles';

export const getIcon = (level) => {
    switch(level){
        case 'error':
            return <ErrorIcon title={level} />
        case 'maintenance':
            return <MaintenanceIcon title={level} />
        default:
            return <SuccessIcon title={level} />
    }
}

const ToastNotification = ({ children, level = 'info', closeButton, ...otherProps }) => (
    <Notification level={level} closeButton={closeButton} {...otherProps}>
        {getIcon(level)}
        <Content level={level}>{children}</Content>
    </Notification>
);

export default ToastNotification;