import React from 'react';

const WarningClockIcon = ({
    colour = 'currentColor', 
    title, 
    ...otherProps
}) => (
    <svg {...otherProps} width={37} height={36} viewBox="0 0 37 36">
        {title && <title>{title}</title>}
        <g fill="none" fillRule="evenodd">
            <path
                d="M32.346 19.15c2.288 1.627 3.779 4.285 3.779 7.288 0 4.952-4.053 8.966-9.054 8.966a9.07 9.07 0 01-7.36-3.743c-.998.193-2.03.294-3.086.294-8.846 0-16.018-7.101-16.018-15.862C.607 7.333 7.78.231 16.625.231s16.018 7.102 16.018 15.862c0 1.046-.102 2.068-.297 3.057zm-13.93 9.928a8.883 8.883 0 01-.398-2.64c0-4.951 4.053-8.965 9.053-8.965.928 0 1.823.138 2.666.394.08-.58.12-1.172.12-1.774 0-7.237-5.924-13.103-13.232-13.103-7.308 0-13.232 5.866-13.232 13.103s5.924 13.104 13.232 13.104c.608 0 1.206-.04 1.792-.12zm-1.791-23.33c.714 0 1.303.533 1.383 1.219l.01.16v9.656c0 .278-.085.549-.242.777l-.103.131-4.875 5.517a1.402 1.402 0 01-1.965.13 1.37 1.37 0 01-.238-1.809l.107-.137 4.53-5.13V7.129c0-.708.538-1.29 1.23-1.37l.163-.01zm9.632 21.208c.042.492.111.857.209 1.097.098.241.27.362.521.362h.168c.251 0 .424-.121.522-.362.097-.24.167-.605.209-1.097l.222-3.795c.042-.74.064-1.27.064-1.592 0-.439-.102-.78-.303-1.027-.074-.09-.166-.145-.256-.202-.025-.018-.045-.04-.071-.056a1.04 1.04 0 00-.94 0c-.028.015-.048.038-.072.056-.09.057-.183.112-.257.202-.201.246-.302.588-.302 1.027 0 .322.021.853.063 1.592l.223 3.795zm.87 5c.292 0 .54-.107.742-.317.201-.213.303-.469.303-.77 0-.345-.106-.614-.317-.804a1.068 1.068 0 00-.741-.29c-.016 0-.028.008-.042.008-.015 0-.029-.007-.043-.007-.283 0-.53.095-.742.289-.211.19-.316.46-.316.804 0 .301.1.557.301.77.204.21.45.316.743.316h.112z"
                fill={colour}
            />
        </g>
    </svg>
);

export default WarningClockIcon
