import { useQuery, useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import notify from '../../components/toast-notifications/notify';
import { unreadThreadCountQuery } from '../../graphql/queries/secure-messages';
import { onNewCustomerMessagesSubscription } from '../../graphql/subscriptions/messages';
import getContent from '../../services/content/getContent';
import { usePrevious } from '../../services/react-hooks';
import { STORE_STATUS_INIT } from '../statuses';
import { useUser } from '../user/hooks';
import { getCustomerMessagesAction, onNewCustomerMessagesAction } from './actions';
import { getCustomerMessagesActiveThread } from './helpers';
import { selectCustomerMessages, selectUnreadCount } from './selectors';
import { UNREAD_THREAD_COUNT_UPDATE } from './types';

export const useUnreadThreadPolling = () => {
    const pollInterval = (process.env.REACT_APP_CUSTOMER_MESSAGE_POLL_INTERVAL_SECONDS || 60) * 1000;
    const dispatch = useDispatch();

    const unreadCountState = useSelector(selectUnreadCount);
    const previousUnreadCount = usePrevious(unreadCountState);
    const { data } = useQuery(
        unreadThreadCountQuery,
        {
            pollInterval,
        },
        true
    );
    let unreadCount = 0;

    const handleNewMessages = useCallback(async () => {
        notify('info', getContent(['customer-messages', 'new-message-toast-notification']));
        await dispatch(getCustomerMessagesAction);
    }, [dispatch]);

    if (data) {
        const { customerMessages } = data;
        unreadCount = customerMessages.unreadCount;
    }

    useEffect(() => {
        if (unreadCount !== previousUnreadCount) {
            dispatch({
                type: UNREAD_THREAD_COUNT_UPDATE,
                payload: unreadCount,
            });
        }

        if (unreadCountState > previousUnreadCount) {
            handleNewMessages();
        }
    }, [dispatch, unreadCount, unreadCountState, previousUnreadCount, handleNewMessages]);
};

export const useCustomerMessages = () => {
    const dispatch = useDispatch();
    const { threadId } = useParams();
    const { status, loading, threads, requested } = useSelector(selectCustomerMessages);
    const getMessages = useCallback(async () => {
        await dispatch(getCustomerMessagesAction);
    }, [dispatch]);

    let activeThread;

    if (!threads || Object.keys(threads).length === 0) {
        activeThread = {
            id: 0,
            subject: getContent(['customer-messages', 'welcome-message', 'subject']),
            messages: [
                {
                    id: 0,
                    body: getContent(['customer-messages', 'welcome-message', 'body']),
                    policyId: null,
                    from: 'ReAssure',
                    read: true,
                    dateTime: null,
                },
            ],
            isWelcome: true,
        };
    } else {
        activeThread = getCustomerMessagesActiveThread(threadId, threads);
    }

    useEffect(() => {
        if (status === STORE_STATUS_INIT && !loading) {
            getMessages();
        }
    }, [getMessages, status, loading]);

    return { status, loading, requested, threads, activeThread };
};

export const useNewMessagesSubscription = () => {
    const { currentLogin } = useUser();
    const dispatch = useDispatch();
    useSubscription(onNewCustomerMessagesSubscription, {
        skip: !currentLogin,
        onData: ({ data }) => {
            if (!data?.data?.onNewCustomerMessages) return;
            dispatch(onNewCustomerMessagesAction(data.data.onNewCustomerMessages));
        },
    });
};
