import { useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { filterFundsByAbiSector } from '../../components/policy-router/fund-switch/helpers';
import { onUpdateAdviserPortfolioDetailsSubscription } from '../../graphql/subscriptions/advisers';
import { getLinkedPolicyCardHeight } from '../../services/portfolios';
import { ADVISER } from '../../services/role-types';
import { ADVISER_PORTFOLIOS_UPDATE_PORTFOLIO } from '../adviser-portfolios/types';
import { useVirtualisedPolicyList } from '../hooks';
import { STORE_STATUS_INIT } from '../statuses';
import { useUser } from '../user/hooks';
import { getAdviserPortfolioDetailsAction, getAdviserPortfolioDetailsResolvedAction } from './actions';
import { selectAdviserPortfolioDetails, selectAdviserPortfolioSectorFilter } from './selectors';
import { ADVISER_PORTFOLIO_SECTOR_FILTER } from './types';

export const useAdviserPortfolioDetails = () => {
    const dispatch = useDispatch();
    const { portfolioId } = useParams();
    const { status, funds, policies, loading, error, editting, abiSectors } = useSelector(state =>
        selectAdviserPortfolioDetails(state, portfolioId)
    );
    const { abiSectorFilter } = useSelector(selectAdviserPortfolioSectorFilter);
    const getPortfolioDetails = useCallback(async () => {
        const result = await dispatch(await getAdviserPortfolioDetailsAction(portfolioId));
        return result;
    }, [dispatch, portfolioId]);

    useEffect(() => {
        if (status === STORE_STATUS_INIT) {
            getPortfolioDetails();
        }
    }, [getPortfolioDetails, status]);

    return {
        status,
        funds,
        filteredFunds: filterFundsByAbiSector(funds, abiSectorFilter),
        policies,
        portfolioId,
        loading,
        error,
        editting,
        abiSectors,
        abiSectorFilter,
    };
};

export const useAdviserPortfolioLinkedPolicies = () => {
    const { status, policies, loading, error } = useAdviserPortfolioDetails();
    const policiesList = useVirtualisedPolicyList(policies, getLinkedPolicyCardHeight);
    return { status, loading, error, policies, policiesList };
};

export const useUpdateAdviserPortfolioDetailsSubscription = () => {
    const dispatch = useDispatch();
    const { currentLogin, category } = useUser();
    useSubscription(onUpdateAdviserPortfolioDetailsSubscription, {
        skip: !currentLogin && category !== ADVISER,
        onData: ({ data }) => {
            if (!data?.data?.onUpdateAdviserPortfolioDetails) return;
            const { portfolio, funds, policies } = data.data.onUpdateAdviserPortfolioDetails;
            const { id: portfolioId } = portfolio;
            dispatch(getAdviserPortfolioDetailsResolvedAction(portfolioId, funds, policies));

            dispatch({
                type: ADVISER_PORTFOLIOS_UPDATE_PORTFOLIO,
                payload: portfolio,
            });
        },
    });
};

export const useAdviserPortfolioFundSectorFilter = sector => {
    const dispatch = useDispatch();
    const { abiSectorFilter } = useAdviserPortfolioDetails();

    const toggleFundSectorFilter = useCallback(() => {
        if (!sector) return;
        if (abiSectorFilter && sector === abiSectorFilter) {
            return dispatch({ type: ADVISER_PORTFOLIO_SECTOR_FILTER, payload: null });
        }
        return dispatch({ type: ADVISER_PORTFOLIO_SECTOR_FILTER, payload: sector });
    }, [dispatch, abiSectorFilter, sector]);

    const selected = abiSectorFilter && sector === abiSectorFilter;
    const deselected = abiSectorFilter && sector !== abiSectorFilter;

    return { abiSectorFilter, toggleFundSectorFilter, selected, deselected };
};
