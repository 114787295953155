import { useSubscription } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onUpdateAdviserPortfoliosSubscription } from '../../graphql/subscriptions/advisers';
import { ADVISER } from '../../services/role-types';
import { useFeatureFlags } from '../app/hooks';
import { STORE_STATUS_INIT, STORE_STATUS_OK, STORE_STATUS_PENDING } from '../statuses';
import { useUser } from '../user/hooks';
import { getAdviserPortfoliosAction } from './actions';
import { selectAdviserPortfolios } from './selectors';
import { ADVISER_PORTFOLIOS_RESOLVED } from './types';

export const useAdviserPortfolios = filters => {
    const dispatch = useDispatch();
    const { status, portfolios, loading, error, noAccess } = useSelector(state =>
        selectAdviserPortfolios(state, filters)
    );
    const getPortfolios = useCallback(async () => {
        await dispatch(getAdviserPortfoliosAction);
    }, [dispatch]);

    useEffect(() => {
        if (status === STORE_STATUS_INIT) {
            getPortfolios();
        }
    }, [getPortfolios, status]);

    return { status, portfolios, loading, error, noAccess };
};

export const useAdviserPortfolio = () => {
    const { status, portfolios, loading, error } = useAdviserPortfolios();
    const { portfolioId } = useParams();
    let portfolio = null;
    let isComplete = null;
    if (!portfolios || !portfolioId) return { status, portfolioId, portfolio, isComplete };
    const portfolioMatches = portfolios.filter(({ id }) => id === portfolioId);
    portfolio = portfolioMatches.length === 1 ? portfolioMatches[0] : null;
    const notFound = !portfolio && status === STORE_STATUS_OK;
    const updatePending = portfolio && portfolio.status === STORE_STATUS_PENDING;
    isComplete = portfolio?.status === 'Complete';
    return { status, portfolioId, portfolio, isComplete, loading, error, notFound, updatePending };
};

export const useUpdateAdviserPortfoliosSubscription = () => {
    const dispatch = useDispatch();
    const { adviserPortfoliosEnabled } = useFeatureFlags();
    const { category } = useUser();
    useSubscription(onUpdateAdviserPortfoliosSubscription, {
        skip: !adviserPortfoliosEnabled || category !== ADVISER,
        onData: ({ data }) => {
            if (!data?.data?.onUpdateAdviserPortfolios) return;
            dispatch({
                type: ADVISER_PORTFOLIOS_RESOLVED,
                payload: data.data.onUpdateAdviserPortfolios,
            });
        },
    });
};
