import { useSubscription } from '@apollo/client';
import notify from '../../components/toast-notifications/notify';
import getContent from '../../services/content/getContent';
import { useFeatureFlags } from '../../store/app/hooks';
import { useUser } from '../../store/user/hooks';
import { onNotificationSubscription } from '../subscriptions/notifications';

const contentMap = {
    CREATE_ADVISER_PORTFOLIO: {
        INFO: getContent([
            'adviser-manage-policies',
            'portfolios',
            'create-adviser-portfolio',
            'notifications',
            'success',
        ]),
        ERROR: getContent([
            'adviser-manage-policies',
            'portfolios',
            'create-adviser-portfolio',
            'notifications',
            'error',
        ]),
    },
    CREATE_ADVISER_PORTFOLIO_INSTRUCTION: {
        INFO: getContent(['adviser-portfolio-instruction', 'notifications', 'received']),
        ERROR: getContent(['adviser-portfolio-instruction', 'notifications', 'error']),
    },
    UPDATE_ADVISER_BACK_OFFICE_INTEGRATION_STATUS: {
        INFO: getContent(['adviser-manage-policies', 'integrations', 'update-status', 'notifications', 'success']),
        ERROR: getContent(['adviser-manage-policies', 'integrations', 'update-status', 'notifications', 'error']),
    },
    UPDATE_ADVISER_PORTFOLIO_POLICIES: {
        INFO: getContent(['adviser-manage-policies', 'portfolios', 'linked-policies', 'notifications', 'success']),
        ERROR: getContent(['adviser-manage-policies', 'portfolios', 'linked-policies', 'notifications', 'error']),
    },
    UPDATE_ADVISER_PORTFOLIO_FUNDS: {
        INFO: getContent(['adviser-manage-policies', 'portfolios', 'funds', 'notifications', 'update-complete']),
        ERROR: getContent(['adviser-manage-policies', 'portfolios', 'funds', 'notifications', 'update-error']),
    },
    UPDATE_ADVISER_PORTFOLIO: {
        ERROR: getContent(['adviser-manage-policies', 'portfolios', 'edit-portfolio', 'notifications', 'error']),
    },
    UPDATE_PROFILE: {
        INFO: getContent(['update-profile-details', 'notifications', 'processed', 'success']),
        ERROR: getContent(['update-profile-details', 'notifications', 'processed', 'error']),
    },
};

export const useNotificationSubscription = () => {
    const { currentLogin } = useUser();
    const { configUpdateEnabled } = useFeatureFlags();
    useSubscription(onNotificationSubscription, {
        skip: !configUpdateEnabled || !currentLogin,
        onData: ({ data }) => {
            if (!data?.data?.onNotification) return;
            const { level, topic } = data.data.onNotification;
            const content = contentMap[topic][level];
            if (!content) return;
            notify(level, content);
        },
    });
};
